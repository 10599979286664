<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>直播管理</el-breadcrumb-item>
      <el-breadcrumb-item>直播预告</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="add('new', '')" size="small">添加</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="agent" label="医生姓名"></el-table-column>
      <el-table-column prop="startTime" label="开始时间"></el-table-column>
      <el-table-column prop="endTime" label="结束时间"></el-table-column>
      <el-table-column prop="status" label="直播状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status == "0" ? "未开始" : "" }}</span>
          <span style="color:green;">{{ scope.row.status == "1" ? "直播中" : "" }}</span>
          <span style="color:red;">{{ scope.row.status == "2" ? "已结束" : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template slot-scope="scope">
          <div class="opt">
            <p>
              <el-button size="mini" type="success" v-if="scope.row.status != -1" icon="el-icon-view" @click="add('bj', scope.row)">编辑
              </el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row, scope.$index)">删除
              </el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="50%">
      <el-form v-model="form" label-width="100px" style="text-align:left;">
        <el-form-item label="标题:">
          <el-input v-model="form.title" size="small"></el-input>
        </el-form-item>
        <el-form-item label="缩略图:">
          <upload :src.sync="form.thumb"></upload>
        </el-form-item>
        <el-form-item label="描述:">
          <el-input v-model="form.desc" size="small"></el-input>
        </el-form-item>
        <el-form-item label="开始时间:">
          <el-date-picker v-model="form.startTime" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" size="small">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间:">
          <el-date-picker v-model="form.endTime" type="datetime" placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" size="small">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择医生:">
          <el-select v-model="value" filterable value-key="id" placeholder="请选择医生" @change="chage" size="small">
            <el-option v-for="item in binddoclist" :key="item.id" :label="item.name" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="sub" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// var Liveserve = "http://192.168.110.220:20210/";
var Liveserve = "https://live-server.51xk.info/";
import upload from "../components/upload";
export default {
  data() {
    return {
      list: [],
      dialogVisible: false,
      type: "",
      row: "",
      title: "添加预告",
      form: {
        title: "",
        thumb: "", //缩略图
        desc: "",
        startTime: "",
        agent: "",
        agentId: "",
        endTime: "",
      },
      binddoclist: [],
      value: "",
    };
  },
  components: {
    upload,
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      this.axios
        .get(Liveserve + "api/LiveSubscribe/get_notice_list")
        .then((res) => {
          if (res.data.code == 1) {
            this.list = res.data.result;
          }
        });
    },
    getdoclist(size) {
      this.axios
        .get("/gu/get_gu_docs?page=1&size=" + size + "&flag=1")
        .then((res) => {
          if (res.data.code == 0) {
            this.binddoclist = res.data.result;
          }
        });
    },
    add(type, row) {
      this.type = type;
      this.row = row;
      this.binddoclist = [];
      if (type == "new") {
        this.title = "添加预告";
        this.form.title = "";
        this.form.thumb = "";
        this.form.desc = "";
        this.form.startTime = "";
        this.value = "";
        this.form.agent = "";
        this.form.agentId = "";
      } else {
        this.title = "编辑预告";
        this.form.title = row.title;
        this.form.thumb = row.thumb;
        this.form.desc = row.desc;
        this.form.startTime = row.startTime;
        this.value = row.agent;
        this.form.agent = row.agent;
        this.form.agentId = row.agentId;
      }
      this.dialogVisible = true;
      this.getdoclist(500);
    },
    chage() {
      this.form.agent = this.value.name; //姓名
      this.form.agentId = this.value.tel;
    },
    sub() {
      var str = "",
        url = "";
      if (this.type == "new") {
        this.form.id = 0;
        this.form.agent = this.value.name; //姓名
        this.form.agentId = this.value.tel; //电话号
        url = "api/LiveSubscribe/add";
        str = "添加成功";
      } else {
        this.form.id = this.row.id;
        url = "api/LiveSubscribe/edit";
        str = "编辑成功";
      }
      //   console.log(this.value);
      // console.log(this.form);
      if (this.form.title == "") {
        this.$message({
          type: "error",
          message: "请填写标题",
        });
        return;
      }
      if (this.form.thumb == "") {
        this.$message({
          type: "error",
          message: "请上传封面图片",
        });
        return;
      }
      if (this.form.desc == "") {
        this.$message({
          type: "error",
          message: "请填写描述",
        });
        return;
      }
      if (this.form.startTime == "") {
        this.$message({
          type: "error",
          message: "请选择开始时间",
        });
        return;
      }
      if (
        this.form.agent == "" ||
        this.form.agent == undefined ||
        this.form.agentId == "" ||
        this.form.agentId == undefined ||
        this.value == ""
      ) {
        this.$message({
          type: "error",
          message: "请选择医生",
        });
        return;
      }

      this.axios
        .post(Liveserve + url, this.qs.stringify(this.form))
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              type: "success",
              message: str,
            });
            this.getlist();
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
      this.dialogVisible = false;
    },
    del(row, index) {
      this.$confirm("此操作将删除该预告, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              Liveserve + "api/LiveSubscribe/del",
              this.qs.stringify({ id: row.id })
            )
            .then((res) => {
              if (res.data.code == 1) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>
